<template>
  <div style="width:99%;margin:auto;">
    <div class="form-title">学生订单详情</div>
    <div class="approve">
      <div class="basic-info-container">
        <h3 class="basic-info">订单信息</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <div class="grid-content1">订单编号：<span class="col-text">{{detailData.code}}</span></div>
            <div class="grid-content1">微信订单号：<span class="col-text">{{detailData.paymentStream || '-'}}</span></div>
            <div class="grid-content1">下单时间：<span class="col-text">{{detailData.createTime}}</span></div>
            <div class="grid-content1">支付时间：<span class="col-text">{{detailData.paymentTime || '-'}}</span></div>
            <div class="grid-content1">支付金额：<span class="col-text red">￥{{detailData.paymentMoney || '-'}}</span></div>
            <div class="grid-content1">订单状态：<span class="col-text">{{getOrderStatusStr(detailData.status)}}</span></div>
          </div>
        </div>
      </div>
      <div class="basic-info-container" v-if="detailData.status==3 || detailData.status==4">
        <h3 class="basic-info">退款信息</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <div class="grid-content1">退款总额：<span class="col-text red">￥{{detailData.refundMoney}}</span></div>
          </div>
        </div>
        <div class="product-header">
          <el-row :gutter="20" class="rol-content">
            <el-col :span="8">
              <div class="grid-content">退款金额</div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content">退款时间</div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content">退款原因</div>
            </el-col>
          </el-row>
        </div>
        <div class="product-content" v-for="(refundInfo,index) in detailData.refundList" :key="index">
          <el-row :gutter="20" class="rol-content">
            <el-col :span="8">
              <div class="grid-content">{{refundInfo.refundMoney}}</div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content">{{refundInfo.refundTime}}</div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content">{{refundInfo.refundReason}}</div>
            </el-col>
          </el-row>
          <el-divider class="divder-product"></el-divider>
        </div>
        
      </div>
      <div class="basic-info-container">
        <h3 class="basic-info">学生信息</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <div class="grid-content1">省：<span class="col-text">{{detailData.buyerInfor.proName}}</span></div>
            <div class="grid-content1">市：<span class="col-text">{{detailData.buyerInfor.cityName}}</span></div>
            <div class="grid-content1">区：<span class="col-text">{{detailData.buyerInfor.regName}}</span></div>
            <div class="grid-content1">街道：<span class="col-text">{{detailData.buyerInfor.streetName}}</span></div>
            <div class="grid-content1">学校：<span class="col-text">{{detailData.buyerInfor.schoolName}}</span></div>
            <div class="grid-content1">年级：<span class="col-text">{{detailData.buyerInfor.gradeName}}</span></div>
            <div class="grid-content1">班级：<span class="col-text">{{detailData.buyerInfor.className}}</span></div>
            <div class="grid-content1">手机号码：<span class="col-text">{{detailData.buyerInfor.phone}}</span></div>
            <div class="grid-content1">学生名字：<span class="col-text">{{detailData.buyerInfor.name}}</span></div>
          </div>
        </div>
      </div>

      <div class="basic-info-container">
        <h3 class="basic-info">商品信息</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <el-table  :data="goodsList" :span-method="objectSpanMethod" border style="width: 50%; margin-top: 20px">
             <el-table-column  prop="id" label="信息卡" width="280">
                 <template slot-scope="scope">
                  <span >编号：{{scope.row.cardCode}}</span><br/>
                  <span >征订天数：{{scope.row.subscriptionDayCount}}</span><br/>
                  <span >征订时间：{{scope.row.effectiveStartTime | formatDate}}-{{scope.row.effectiveEndTime | formatDate}}</span><br/>
                  <span >批次：{{scope.row.batch}}</span><br/>
                 </template>
              </el-table-column>     
             <el-table-column  prop="id" label="套餐" width="250"> 
              <template slot-scope="scope">
                <div @click.stop="showBigImage(scope.row.packageImage)" >
                  <el-image style="width:50px;height:50px;" :src="scope.row.packageImage"  ></el-image>
                </div>
                <span >名称：{{scope.row.packageName}}</span><br/>
                <span >单价：￥{{scope.row.packageUnitPrice}}</span><br/>
              </template>
             </el-table-column>    
             <el-table-column  prop="id" label="组合" width="250"> 
               <template slot-scope="scope">
                <div @click.stop="showBigImage(scope.row.combinationImage)" >
                  <el-image style="width:50px;height:50px;" :src="scope.row.combinationImage"  ></el-image>
                </div>
                <span >名称：{{scope.row.combinationName}}</span><br/>
                <span >单价：￥{{scope.row.combinationUnitPrice}}</span><br/>
              </template>
             </el-table-column>    
             <el-table-column  prop="id" label="商品" >
                <template slot-scope="scope">
                  <div @click.stop="showBigImage(scope.row.goodsImage)" >
                    <el-image style="width:50px;height:50px;" :src="scope.row.goodsImage"  ></el-image>
                  </div>
                  <span >名称：{{scope.row.name}}</span><br/>
                  <span >单价：￥{{scope.row.unitPrice}}</span><br/>
                </template>
              </el-table-column>    
            </el-table>
          </div>
        </div>
      </div>
      

    </div>

    <!-- <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
    </div> -->
    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
    </div>

     <el-image-viewer v-if="imgDialogVisible" :on-close="onClose" :url-list="[bigImageUrl]" />
  </div>
</template>

<script>
import { getStuOrderInfo } from '@/api/index.js'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'CustomerForm',
  components: { ElImageViewer },
  props: ['value', 'editId'],
  data() {
    return {
      activeNames: ['1', '2', '3', '4'],
      detailData:{
        buyerInfor:{}
      },
      goodsList:[],
      combinationId:'',
      imgDialogVisible:false,//图片预览
      bigImageUrl:'',
    }
  },
  created() {
    this.getStuOrderInfo();
  },
  mounted() {
    
  },
  methods: {
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list','','stu')
    },
    getOrderStatusStr(statusCode){
      let statusStr = '';
      switch(statusCode){
        case '0':
          statusStr = '待支付';
          break;
        case '1':
          statusStr = '支付中';
          break;
        case '2':
          statusStr = '已支付';
          break;
        case '3':
          statusStr = '全额退款';
          break;
        case '4':
          statusStr = '部分退款';
          break;
        case '5':
          statusStr = '已关闭';
          break;
      }
      return statusStr;
    },
    //编辑获取详情
    getStuOrderInfo(){
      let param = {id:this.editId};      
      getStuOrderInfo(param).then(res => {
        if (res.code != 200) return
          this.detailData = res.data;     
          this.initGoodsList(this.detailData.subscriptionInfor)   
      })
    },
    initGoodsList(cardInfo){
      let _goodsList = [];
      for(let packageInfo of cardInfo.packageList){
        for(let combinationInfo of packageInfo.combinationList){
          for(let goodsInfo of combinationInfo.goodsList){
            goodsInfo.batch = cardInfo.batch;
            goodsInfo.cardCode = cardInfo.code;
            goodsInfo.effectiveStartTime = cardInfo.effectiveStartTime;
            goodsInfo.effectiveEndTime = cardInfo.effectiveEndTime;
            goodsInfo.subscriptionDayCount = cardInfo.subscriptionDayCount;

            goodsInfo.packageName = packageInfo.name;
            goodsInfo.packageImage = packageInfo.thumbnailList[0].url;
            goodsInfo.packageUnitPrice = packageInfo.unitPrice;

            goodsInfo.combinationName = combinationInfo.name;
            goodsInfo.combinationUnitPrice = combinationInfo.unitPrice;
            goodsInfo.combinationImage = combinationInfo.thumbnailList[0].url;
            goodsInfo.combinationId == combinationInfo.originalId;

            goodsInfo.goodsImage = goodsInfo.thumbnailList[0].url;
            _goodsList.push(goodsInfo);
          }
        }
      }
      this.goodsList = _goodsList;

    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let goodsCount = this.goodsList.length;
      if( columnIndex==0 ||columnIndex==1 ){
        if(rowIndex==0){
            return [goodsCount,1]
        }else{
          return [0,0]
        }
        
      }
      if(columnIndex == 2 ){
        let rowspan = 0;
        for(let _index = rowIndex;_index<this.goodsList.length;_index++){
          if(row.combinationId == this.goodsList[_index].combinationId){
            rowspan++
          }
        }
        
        //修改this.combinationId好像会触发死循环错误，但好像不影响效果
        if(row.combinationId == this.combinationId){
          this.combinationId = row.combinationId
          return [0,0]
        }else{
          this.combinationId = row.combinationId
          return [rowspan,1]
        }
        

      }   
      

    },
        //查看大图
    showBigImage(url){
      console.log('老弟来了')
      this.imgDialogVisible = true;
      this.bigImageUrl = url;
    },
    //关闭大图
    onClose() {
      this.imgDialogVisible = false
    },

  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/info.scss';
</style>

